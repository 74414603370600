:root {
  --background-color: #210b2c;
  --accent-color: #55286f;
  --foreground-color: #bc96e6;
  --text-color: #d8b4e2;
  --button-color: #ae759f;
  --button-text-color: #55286f;
}

body,
html {
  background-color: var(--background-color);
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  /*background-color: var(--background-color);*/
}

.title {
  font-size: 2.5rem;
  color: var(--text-color);
  margin: 10px;
}

.blurb {
  font-size: 1.25rem;
  color: var(--text-color);
}

.pagecontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
}

.button {
  color: var(--background-color);
  background: var(--button-color);
}


.feedback-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--button-color); /* Main button background color */
  color: var(--button-text-color); /* Icon/text color */
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10000; /* Ensure it appears above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-button i {
  color: var(--button-text-color); /* Ensure icon color matches text */
  font-size: 18px;
}

.feedback-button:hover {
  background-color: var(--accent-color); /* Change background on hover */
  color: var(--text-color); /* Ensure icon/text color changes on hover */
}

.feedback-button:hover i {
  color: var(--text-color); /* Ensure icon color changes on hover */
}

/* For mobile devices */
@media (max-width: 992px) {
  .feedback-button {
    width: 50px; /* Make the button a square */
    height: 50px; /* Same height and width for a square shape */
    padding: 0; /* Remove padding */
    font-size: 18px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative; /* Ensure relative positioning for the close button */
  background: var(--foreground-color);
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: black; /* Ensure contrast */
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other elements */
}

.modal-close-button:hover {
  color: red; /* Highlight effect for better UX */
}
